canvas {
  height: 50vh; 
  width: 50vh;
}
.intro{
  display:grid;
  margin: auto;
  padding-top: 100px; 
  padding-left: 300px;
}
.my-name {
  margin-bottom: 8px;
  color: #ededed;
  font-weight: bold;
  font-size: 40px;

}
.my-job{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.dash {
  margin-top: 20px;
  border: 0.5px solid #282828;
  width: 180px;
}
.detail-self{
  margin: auto;
  padding-top: 20px;
  padding-left: 300px;
}
.detail-intro{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}
.info-cattery{
  margin-top: 20px;
}
.fb-icon{
  background-color: #282828;
  border: 1px solid;
  color: green;
  border-radius: 5px;
  margin-right: 9px;
  cursor: pointer;
}
.tik-icon{
  background-color: #282828;
  border: 1px solid;
  color: yellowgreen;
  border-radius: 5px;
  margin-right: 9px;
  cursor: pointer;
}
.hint{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}
.dash-2{
  margin-top: 20px;
  border: 0.5px solid #282828;
  width: 120px;
}
.contact{
  display: block;
  margin: auto;
  padding-top: 20px;
  padding-left: 300px;
}
.top{
  display: flex;
}
.cv{
  background-color: #282828;
  color: #a0a0a0;
  border: 1px solid;
  font-weight: bold;
  border-radius: 7px;
  cursor: pointer;
}
.cv:hover{
  color: white;
}
.dash-y{
  margin: 0 15px 0 15px;
  border: 0.5px solid #282828;
  height: 20px;
}
.my-fb{
  margin-right: 15px;
  color: #a0a0a0;
  cursor: pointer;
}
.my-fb:hover{
  color: white;
}
.my-ig{
  margin-right: 15px;
  color: #a0a0a0;
  cursor: pointer;
}
.my-ig:hover{
  color: white;
}
.my-git{
  margin-right: 15px;
  color: #a0a0a0;
  cursor: pointer;
}
.my-git:hover{
  color: white;
}
.my-steam{
  margin-right: 15px;
  color: #a0a0a0;
  cursor: pointer;
  
}
.my-steam:hover{
  color: white;
}
.my-sea{
  margin-right: 15px;
  color: #a0a0a0;
  cursor: pointer;
  
}
.my-sea:hover{
  color: white;
}
.dash-3{
  margin-top: 20px;
  border: 0.5px solid #282828;
  width: 80px;
}
.box{
  margin: auto;
  padding-top: 20px;
  padding-left: 300px;
}
.dash-x{
  margin-top: 50px;
  border: 0.5px solid #282828;
  width: 180px;
  margin-bottom: 20px;
}
.project{
  display: flex;
}
.duan {
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}
.hat{
  margin: 0 15px 0 15px;
  color:#a0a0a0;
}
.text-box{
  margin: 20px 50px 100px 50px;
}
.jetking{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}
.poem {
  display: flex;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.dash-5{
  margin: 0 15px 0 15px;
  border: 2px solid #cbcbcb;
  height: 18px;
}
.poem-text{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}
.jcsec{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}
.show{
  margin: auto;
  padding-top: 20px;
  padding-left: 300px;
}
.dash-6{
  border: 0.5px solid #282828;
  width: 180px;
  margin-bottom: 20px;
}
.project{
  display: flex;
}
.mask{
  margin: 0 15px 0 15px;
  color: #a0a0a0;
}
.loading {
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}
.nike{
  margin-left: 20px;
  margin-right: 15px;
  color: greenyellow;
  cursor: pointer;
  
}
.nike:hover{
  color: white;
}
.dress{
  margin-right: 15px;
  color: royalblue;
  cursor: pointer;
  
}
.dress:hover{
  color: white;
}
.fume{
  margin-right: 15px;
  color: orange;
  cursor: pointer;
  
}
.fume:hover{
  color: white;
}
.nike{
  margin-left: 20px;
  margin-right: 15px;
  color: greenyellow;
  cursor: pointer;
  
}
.nike:hover{
  color: white;
}
.bot{
  margin-right: 15px;
  color: yellow;
  cursor: pointer;
  
}
.bot:hover{
  color: white;
}
.dash-7{
  border: 0.5px solid #282828;
  width: 150px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.loading-cer{
  margin: auto;
  padding-left: 300px;
  margin-bottom: 30px;
}
.goal{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.lang{
  margin-left: 20px;
  margin-right: 15px;
  color: #a0a0a0;
  cursor: pointer;
  
}
.lang:hover{
  color: white;
}
#Slider {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
}

.aspect-ratio-169 {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */

}

.aspect-ratio-169 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dot-container{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: -1440px;
}
.dot{
  height: 12px;
  width: 12px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
}
.dot.active{
  background-color: red;
}
.last{
  margin: auto;
  margin-top: 100px;
  padding-left: 300px;
  margin-bottom: 30px;
}
.dash-9{
  border: 0.5px solid #282828;
  width: 180px;
  margin-bottom: 20px;
}
.exp{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}
.last-name{
  margin-top: 20px;
  display:grid;
}
.van{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}
.coppy{
  color: #a0a0a0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
}